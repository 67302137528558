import { AxiosInstance, AxiosStatic } from 'axios'
// Options
import { globalOptions } from 'resource-endpoint'
// Endpoint Class
import { Endpoint } from '../endpoint'
// Endpoints
import { Auth } from './auth'
import { Contact } from './contact'
import { Misc } from './misc'
import { Password } from './password'
import { Role } from './role'
import { User } from './user'

export { Auth, Contact, Misc, Password, Role, User }

export class Api {
  get auth(): Auth {
    return new Auth()
  }

  get axios(): AxiosStatic | AxiosInstance {
    return globalOptions.axios
  }

  get contact(): Contact {
    return new Contact()
  }

  get crud(): Endpoint {
    return new Endpoint()
  }

  get misc(): Misc {
    return new Misc()
  }

  get password(): Password {
    return new Password()
  }

  get role(): Role {
    return new Role()
  }

  get user(): User {
    return new User()
  }
}

export const api = new Api()
