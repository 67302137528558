import { Options, GlobalOptions, setGlobalOptions } from 'resource-endpoint'

export * from './api'
export * from './endpoint'
export * from './types'

export class ClientOptions extends Options {
  constructor(options?: GlobalOptions) {
    super({
      path: 'api',
      withCredentials: true,
      ...options,
    })
  }
}

export const configure = (options?: GlobalOptions): void => {
  setGlobalOptions(new ClientOptions(options))
}

// set ClientOptions as default globalOptions singleton when this module is loaded
// This library is very specific so it's okay, this library should not be used except for
// frontend usage, otherwise this may create confusion.
configure()
