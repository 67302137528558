import { Endpoint } from '../endpoint'
import { ApiResponse, ContactUsValues } from '../types'

export class Contact extends Endpoint {
  async contactUs(values: ContactUsValues): Promise<ApiResponse<Record<any, any>>> {
    const data = {
      email: values.email,
      name: values.name,
      message: values.message,
      contactByFaxOnly: values.contactByFaxOnly,
    }
    return this.post('contact', { data })
  }
}
