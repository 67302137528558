import { Endpoint } from '../endpoint'
import { ApiResponse, LoginValues, RegisterValues, UserResponse } from '../types'

export class Auth extends Endpoint {
  async login(values: LoginValues): Promise<ApiResponse<UserResponse>> {
    const data = { email: values.email, password: values.password }
    return this.post('login', { data })
  }

  async logout(): Promise<ApiResponse<Record<any, any>>> {
    return this.post('logout')
  }

  async register(values: RegisterValues): Promise<ApiResponse<UserResponse>> {
    const { emailConfirmation, passwordConfirmation, ...inputs } = values
    const data = {
      ...inputs,
      emailConfirmation,
      passwordConfirmation,
    }
    return this.post('register', { data })
  }
}
