import { ApiResponse, AssignRoleValues, ChangePasswordValues, UserEntity, UserResponse } from '../types'
import { Endpoint } from '../endpoint'

export class User extends Endpoint {
  get resource(): string {
    return 'user'
  }

  async assignRole(values: AssignRoleValues): Promise<ApiResponse<UserResponse>> {
    const { userId, roleId } = values
    return this.patch('role/assign', { data: { userId, roleId } })
  }

  async changePassword(values: ChangePasswordValues): Promise<ApiResponse<Record<any, any>>> {
    const data = {
      currentPassword: values.currentPassword,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
    }
    return this.put('password/change', { data })
  }

  async current(): Promise<ApiResponse<UserResponse | UserResponse<null>>> {
    return this.get()
  }

  async resendEmailVerification(): Promise<ApiResponse<Record<any, any>>> {
    return this.post('email/resend')
  }

  async updateCurrent(values: Pick<UserEntity, 'name' | 'email'>): Promise<ApiResponse<UserResponse>> {
    const data = {
      email: values.email,
      name: values.name,
    }
    return this.put(undefined, { data })
  }

  async verifyEmail(token: string): Promise<ApiResponse<UserResponse>> {
    return this.put('email/verify', { data: { token } })
  }
}
