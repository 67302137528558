import { CancelToken } from 'axios'
import { Endpoint } from '../endpoint'
import { ApiResponse, RoleEntity, RoleResponse } from '../types'

export class Role extends Endpoint {
  get resource(): string {
    return 'role'
  }

  // Display a listing of the roles.
  async index(params?: any, cancelToken?: CancelToken): Promise<ApiResponse<{ roles: RoleEntity[] }>> {
    return this.get(undefined, { params, cancelToken })
  }

  // Store a newly created role in storage.
  async store(data: Pick<RoleEntity, 'name'>, params?: any): Promise<ApiResponse<RoleResponse>> {
    return this.post(undefined, { data, params })
  }

  // Display the specified role.
  async show(
    id: number,
    params?: any,
    cancelToken?: CancelToken,
  ): Promise<ApiResponse<RoleResponse<RoleEntity | undefined>>> {
    return this.get(`${id}`, { params, cancelToken })
  }

  // Update the specified role in storage.
  async update(id: number, data: Pick<RoleEntity, 'name'>, params?: any): Promise<ApiResponse<RoleResponse>> {
    return this.put(`${id}`, { data, params })
  }

  // Store or update specified role in storage.
  async storeOrUpdate(
    id: undefined | null | number,
    data: Pick<RoleEntity, 'name'>,
    params?: any,
  ): Promise<ApiResponse<RoleResponse>> {
    if (!id) {
      return this.store(data, params)
    }
    return this.update(id, data, params)
  }

  // Remove the specified role from storage.
  async destroy(id: number, params?: any): Promise<ApiResponse<Record<any, any>>> {
    return this.delete(`${id}`, { params })
  }
}
