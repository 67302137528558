import { CancelToken } from 'axios'
import { Endpoint } from '../endpoint'
import { ApiResponse } from '../types'

export class Misc extends Endpoint {
  async info(cancelToken?: CancelToken): Promise<ApiResponse<{ revision: string; version: string }>> {
    return this.get(undefined, { cancelToken })
  }

  async csrfCookie(cancelToken?: CancelToken): Promise<ApiResponse<Record<any, any>>> {
    return this.get('csrf-cookie', { cancelToken })
  }
}
