(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("axios"), require("prop-types"), require("qs"), require("react"), require("url-join"));
	else if(typeof define === 'function' && define.amd)
		define(["axios", "prop-types", "qs", "react", "url-join"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("axios"), require("prop-types"), require("qs"), require("react"), require("url-join")) : factory(root["axios"], root["prop-types"], root["qs"], root["react"], root["url-join"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__375__, __WEBPACK_EXTERNAL_MODULE__520__, __WEBPACK_EXTERNAL_MODULE__743__, __WEBPACK_EXTERNAL_MODULE__937__, __WEBPACK_EXTERNAL_MODULE__601__) {
return 