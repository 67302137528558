import { Endpoint } from '../endpoint'
import { ApiResponse, ResetPasswordValues } from '../types'

export class Password extends Endpoint {
  get resource(): string {
    return 'password'
  }

  async requestPasswordReset(email: string): Promise<ApiResponse<Record<any, any>>> {
    return this.post('forgot', { data: { email } })
  }

  async resetPassword(values: ResetPasswordValues): Promise<ApiResponse<Record<any, any>>> {
    const data = {
      token: values.token,
      password: values.password,
      passwordConfirmation: values.passwordConfirmation,
    }
    return this.put('reset', { data })
  }
}
