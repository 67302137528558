(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("resource-endpoint"), require("url-join"));
	else if(typeof define === 'function' && define.amd)
		define(["resource-endpoint", "url-join"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("resource-endpoint"), require("url-join")) : factory(root["resource-endpoint"], root["url-join"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__939__, __WEBPACK_EXTERNAL_MODULE__601__) {
return 