import { AxiosResponse } from 'axios'

export * from './auth'
export * from './contact'
export * from './password'
export * from './user'

export type Location = 'body' | 'cookies' | 'headers' | 'params' | 'query'

export interface ValidationError {
  location: Location
  param: string
  value: any
  msg: any
}

export interface ValidationResponse {
  message?: string
  errors?: ValidationError[] | undefined
}

export type ApiResponse<Data = any, E = ValidationResponse> = AxiosResponse<Data | E>
