import { ApiMixin, Constructor, HandleErrorMixin, SessionCsrfCookieMixin } from 'resource-endpoint'
import urljoin from 'url-join'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function HandleApiMixin<T extends Constructor<any>>(superClass: T) {
  return class extends SessionCsrfCookieMixin(HandleErrorMixin(ApiMixin(superClass))) {
    get csrfPath(): string {
      return urljoin(super.path, 'csrf-cookie')
    }

    errorsBlock(): any {
      return undefined
    }
  }
}
